import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const ListCupom = () => import("./components/ListCupom.vue")
const ListUserPendingSolicitations = () => import("./components/solicitations/ListUserSolicitations.vue")
const ListProductPendingSolicitations = () => import("./components/solicitations/ListProductSolicitations.vue")
const ListUsers = () => import("./components/users/ListUsers.vue")
const ListGroups = () => import("./components/groups/ListGroups.vue")
const CreateCupom = () => import("./components/CreateCupom.vue")
const EditCupom = () => import("./components/EditCupom.vue")
const EditUserSolicitation = () => import("./components/solicitations/EditUserSolicitation.vue")
const EditProductSolicitation = () => import("./components/solicitations/EditProductSolicitation.vue")
const EditUser = () => import("./components/users/EditUser.vue")
const EditGroup = () => import("./components/groups/EditGroup.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/solicitacoes/lojista",
    name: "listUserSolicitations",
    component: ListUserPendingSolicitations,
  },
  {
    path: "/solicitacoes/produto",
    name: "listProductSolicitations",
    component: ListProductPendingSolicitations,
  },
  {
    path: "/solicitacoes/lojista/:id",
    name: "editUserSolicitation",
    component: EditUserSolicitation,
  },
  {
    path: "/solicitacoes/produto/:id",
    name: "editProductSolicitation",
    component: EditProductSolicitation,
  },
  {
    path: "/lojistas/",
    name: "listUsers",
    component: ListUsers,
  },
  {
    path: "/lojistas/:id",
    name: "editUser",
    component: EditUser,
  },
  {
    path: "/produtos/",
    name: "listGroups",
    component: ListGroups,
  },
  {
    path: "/produto/:id",
    name: "editGroup",
    component: EditGroup,
  },
  {
    path: "/cupom/novo",
    name: "createCupom",
    component: CreateCupom,
  },
  {
    path: "/cupom/:id",
    name: "editCupom",
    component: EditCupom,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;