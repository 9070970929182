<template>
  <div id="app">
    <nav class="navbar navbar-expand">
      <a href="/" class="navbar-brand">
        <img style="max-width: 120px;" src="@/assets/logo-padrao-color.png" alt="">
      </a>
      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" /> Login
          </router-link>
        </li>
      </div>
      <div v-if="currentUser">
        <div class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'listUsers' }">
              Vendedores
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'listGroups' }">
              Produtos
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'listGroups' }">
              Vendas
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Solicitações de Cadastro</a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'listUserSolicitations' }">
                  Lojistas
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'listProductSolicitations' }">
                  Produtos
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="logOut">
              <font-awesome-icon icon="sign-out-alt" /> Sair
            </a>
          </li>
        </div>
      </div>
    </nav>

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss">
  #app {
    background: #f5f5f5;
  }
  .navbar {
    background: #f5f5f5;
  }
</style>
